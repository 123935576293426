const hardcodedUserTenantSlugWhitelists = {
  "0b3bc07f-c8fb-4ee0-bc86-5cb04aba7ee6": [
    "conemaugh-memorial",
    "conemaugh-miners",
    "conemaugh-east-hills",
  ],
  "b3d8c713-b253-409e-b1ee-08ace774dfc2": [
    "conemaugh-memorial",
    "conemaugh-miners",
    "conemaugh-east-hills",
  ],
  "551532c5-d0a9-4127-9944-976cd5cb44e9": [
    "conemaugh-memorial",
    "conemaugh-miners",
    "conemaugh-east-hills",
  ],
  "74f8ae01-888f-4978-83e8-6816026c53ab": [
    "medical-center-of-aurora",
    "centennial-hospital",
  ],
  "360e9975-017e-4dc7-9f1e-d66254f2be98": [
    "medical-center-of-aurora",
    "centennial-hospital",
  ],
  "f23c5d57-489c-4a84-8bfd-94fada42c0b1": [
    "medical-center-of-aurora",
    "centennial-hospital",
  ],
  "dd874614-81c3-462c-8c05-10982c6c6144": [
    "medical-center-of-aurora",
    "centennial-hospital",
  ],
  "b11fad45-11c8-4636-859c-ee577c6bbd99": [
    "medical-center-of-aurora",
    "centennial-hospital",
  ],
  "6223f399-ae60-43e6-a670-ad1ae31cb536": [
    "medical-center-of-aurora",
    "centennial-hospital",
  ],
  "1be5ee18-5913-4ae2-968f-40a74d92d93c": [
    "wesley-medical-center",
    "wesley-woodlawn",
  ],
  "c78f284e-0b47-495e-b56a-4d5e4d7ef0b6": [
    "wesley-medical-center",
    "wesley-woodlawn",
  ],
  "68dba8a3-399e-4349-93e1-34ac40511e37": [
    "wesley-medical-center",
    "wesley-woodlawn",
  ],
  "cf251697-7ae5-4f07-b330-81cd0bf57349": [
    "wesley-medical-center",
    "wesley-woodlawn",
  ],
  "2ff18ae2-3d3f-4b73-ae06-b3d7f45c4acb": [
    "wesley-medical-center",
    "wesley-woodlawn",
  ],
  "033a1862-36f0-442c-8c09-c1a1d2f9ec40": [
    "trios-health",
    "lourdes-medical-center",
  ],
  "fc4042a1-4cea-4dbc-aac9-3ed88e332869": [
    "wythe-county",
    "twin-county-regional",
  ],
  "734e35af-855e-4f30-88a0-f80fbed69b81": [
    "georgetown-community",
    "bluegrass-community",
  ],
  "c5dbd3aa-9c17-4469-aeb2-a9d66def42de": [
    "haywood-regional",
    "harris-regional-hospital",
  ],
  "b6bf4fe6-cdcf-46ee-a41d-e03b1cc513ef": [
    "clark-memorial-hospital",
    "scott-memorial-health",
  ],
  "28686cb6-6be7-4c0c-9eea-a20d518b7c59": [
    "bourboncommunityhospital",
    "clark-regional-medical",
  ],
  "aa994b60-393c-4ea3-bb7e-4d42f9d1325b": [
    "lake-cumberland-regional",
    "lake-cumberland-sc",
  ],
  "f8fe55ba-9643-4fe5-a662-8bc275a3f0ac": [
    "sumner-regional",
    "riverview-regional-mc",
  ],
  "e419c88b-e0c5-4635-b4c9-95a02a54283a": [
    "north-al-medical-center",
    "na-shoals-hospital",
  ],
  "84ac9bec-e05f-4d0a-815d-5b4300231ad0": [
    "trios-health",
    "lourdes-medical-center",
  ],
  "0adb16c6-b110-4508-a551-1a98f013de2d": [
    "clark-regional-medical",
    "bourboncommunityhospital",
  ],
  "316566c7-f5e6-46fa-a740-ddb881766e89": [
    "clark-regional-medical",
    "bourboncommunityhospital",
  ],
  "9b5faaa8-d277-4895-a181-b68b97b7ba05": [
    "clark-regional-medical",
    "bourboncommunityhospital",
  ],
  "7b3ec304-45e6-420a-b0f7-7a79183c8d47": [
    "clark-regional-medical",
    "bourboncommunityhospital",
  ],
  "6251c996-c6a3-4be1-a29e-c165b789f851": [
    "frye-regional-surgery",
    "frye-regional",
  ],
  "c5871e2a-74be-4cf6-aec8-72b5dc3698d7": [
    "bluegrass-community",
    "georgetown-community",
  ],
  "b976f627-2111-4238-a1a7-6f4477d80f2f": [
    "bluegrass-community",
    "georgetown-community",
  ],
  "f89b57c9-13e5-44b0-9c32-1e0c1ce1408e": [
    "north-al-medical-center",
    "na-shoals-hospital",
  ],
  "89b38c05-6c9a-48c9-a759-271b93722bcc": [
    "conemaugh-memorial",
    "conemaugh-miners",
    "conemaugh-east-hills",
  ],
  "7b60b371-b6cd-4bb7-bf6b-6306470dda80": [
    "conemaugh-memorial",
    "conemaugh-miners",
    "conemaugh-east-hills",
  ],
  "5ef3cbc6-e279-4796-a5ad-e230a1d307d2": [
    "conemaugh-memorial",
    "conemaugh-miners",
    "conemaugh-east-hills",
  ],
  "c8359e06-e9d3-474a-878c-7dfe3bfd4d85": [
    "fleming-county-hospital",
    "meadowview-regional-mc",
  ],
  "5a6d00d7-11e3-493b-8bc5-ebaa3b232d56": [
    "fleming-county-hospital",
    "meadowview-regional-mc",
  ],
  "2f4a6a72-c11f-4dc1-bbe7-8d5399472329": [
    "frye-regional",
    "frye-regional-surgery",
  ],
  "eff7dc5b-2b8c-4281-84f7-871cb0bdf305": [
    "sovah-health-danville",
    "sovah-health-martinsville",
  ],
  "40b793a0-6867-4dee-9d42-afc4db57cbf7": [
    "person-memorial-hospital",
    "maria-parham-health",
  ],
  "4badd301-b2d9-4512-99a8-e0bab86e30bf": [
    "lake-cumberland-sc",
    "lake-cumberland-regional",
  ],
  "bf55ec7e-7fd2-4298-b4ce-0749cd57e765": [
    "north-al-medical-center",
    "na-shoals-hospital",
  ],
  "55123b87-6bce-43f0-b0ca-6c3367be62c2": [
    "sumner-regional",
    "riverview-regional-mc",
  ],
  "9eafe2ed-d859-4bee-8b1f-f36b4fea1caa": [
    "sumner-regional",
    "riverview-regional-mc",
  ],
  "13894ab5-7c62-4fc4-86a6-809dda803499": [
    "strhs-lawrenceburg",
    "strhs-pulaski",
  ],
};

const filterTenantAccess = (state, props) => {
  const user = state.auth.loggedInUser.getIn(["data", "user"]);

  if (!user) return null;

  let tenantSubdomains = user.get("subdomains");

  if (hardcodedUserTenantSlugWhitelists.hasOwnProperty(user.get("id"))) {
    tenantSubdomains = user.get("subdomains").filter((subdomain) => {
      return hardcodedUserTenantSlugWhitelists[user.get("id")].find(
        (hardCodedSlug) => hardCodedSlug === subdomain.get("slug")
      );
    });
  }

  return tenantSubdomains;
};

export default filterTenantAccess;
